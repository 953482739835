import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from './../../elements/Image';

const propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string
}

const defaultProps = {
  src: undefined,
  width: undefined,
  height: undefined,
  alt: undefined
}

const Banner = ({
  className,
  src,
  width,
  height,
  alt,
  ...props
}) => {

  const classes = classNames(
    'banner',
    className
  );

  return (
    <div className={classes}>
      <Link to="/contact">
        <Image {...props} src={src} alt={alt} width={width} height={height}/>
      </Link>
      <p className="m-8 h6-mobile center-content-mobile">
{/*        Voor meer informatie over deze promo, neem <a href='/contact'>contact</a> met ons op!*/}
        <center>Voor meer informatie en gratis tickets, neem <a href='/contact'>contact</a> met ons op!</center>
      </p>
    </div>
  );
}

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;

export default Banner;
